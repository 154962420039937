export const LIST_EMAILS = {
  development: [
    'yumi.gikwang+admin@gmail.com',
    'linh.nguyenmai@saigontechnology.com',
    'binh.ho@saigontechnology.com',
    'linh.nguyenmai+cso@gmail.com',
    'linh.nguyenmai+mhssupport@gmail.com',
  ],
  staging: [
    'yumi.gikwang+admin@gmail.com',
    'minmed.mink+ngoclabadmin@gmail.com',
    'phuc.tran0211+superadmin@gmail.com',
    'linh.nguyenmai@saigontechnology.com',
    'binh.ho@saigontechnology.com',
    'weikian@minmed.sg',
    'yumi.gikwang+stscso@gmail.com',
    'Chloe.goh@minmed.sg',
  ],
  preProd: [
    'yumi.gikwang+admin@gmail.com',
    'minmed.mink+ngoclabadmin@gmail.com',
    'phuc.tran0211+superadmin@gmail.com',
  ],
  production: [
    'tranthimyngoc.77@gmail.com',
    'phuc.tran0211+admin@gmail.com',
    'minmed.mink+ngocstsadmin@gmail.com',
    'yumi.gikwang+stscso@gmail.com',
    'yumi.gikwang+stsmhssupport@gmail.com',

    'emmi@minmed.sg',
    'mariam@minmed.sg',
    'juliana@minmed.sg',
    'jumana@minmed.sg',
    'wenjie@minmed.sg',

    'Ask1@minmed.sg',
    'Ask2@minmed.sg',
    'Ask3@minmed.sg',
    'Ask4@minmed.sg',
    'Ask5@minmed.sg',

    'chuanyeong@minmed.sg',
    'jolyn@minmed.sg',
    'angerline@minmed.sg',
    'sarah@minmed.sg',
    'minghui@minmed.sg',
    'rachel.lim@minmed.sg',

    'Chloe.goh@minmed.sg',
  ],
};

export const renderListEmails = () => {
  return LIST_EMAILS[process.env.REACT_APP_API_PREFIX] || [];
};
